<template>
  <div
    id="wrapper-mobile-size"
  >
    <div class="mobile-size">
      <img :src="require('@/assets/images/pages/vote-success.svg')">
      <div class="greetings">
        Selamat, <span>{{ $store.state.user.userLoggedIn.nama_panggilan }}!</span>
      </div>
      <div class="info">
        Anda sudah melakukan E-Vote Ketua Umum ILUNI UI. Terima kasih atas partisipasi Anda.
      </div>
      <div
        class="logout"
        @click="logout"
      >
        <feather-icon
          class="mr-25"
          icon="LogOutIcon"
        />
        <span>Logout</span>
      </div>
    </div>
  </div>
</template>

<style scoped>
  @media (max-width: 640px) {
    .mobile-size {
      width: 100vw !important;
    }
    .logout {
      display: none !important;
    }
  }

  #wrapper-mobile-size {
    display: flex;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: #E5E5E5;
  }

  .mobile-size {
    width: 360px;
    height: 100vh;
    background-color: #F9F9F9;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 28px;
  }

  .mobile-size img {
    height: 160px;
    width: 160px;
    margin-bottom: 36px;
  }

  .mobile-size .greetings {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    text-align: center;

    color: #1B1B1B;
  }

  .mobile-size .greetings span {
    color: #4FBBEA;
  }

   .mobile-size .info {
    margin-top: 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;

    /* primary */

    color: #1B1B1B;
   }

   .logout {
    position: absolute;
    bottom: 90px;
    background: #FFFFFF;
    right: 24px;
    cursor: pointer;
    padding: 8px 16px;
    border-radius: 20px;
    color: #1B1B1B;
  }
</style>

<script>
export default {
  components: {
  },
  data() {
    return {

    }
  },
  methods: {
    async logout() {
      this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)
      await this.$store.dispatch('user/logout')

      // Redirect to login page
      window.location.replace('/login/tps')
    },
  },
}
</script>
